<template>
  <div class="my-exam-container">
    <div class="list">
      <div class="item" v-for="(item,index) in memberExamList" :key="index">
        <div class="item-title">
          <div class="title">{{item.examName}}</div>
          <div class="exam-number" v-if="item.status==1">考号：<span class="txt">{{item.examNumber}}</span></div>
        </div>
        <div class="item-content">
          <div class="content">
            <div class="img-box">
              <img :src="item.photo" class="head-img" alt="">
            </div>
            <div class="block">
              <div class="name">姓名：<span class="txt">{{item.name}}</span></div>
              <div class="birthday">生日：<span class="txt">{{item.birthday}}</span></div>
              <div class="email">邮箱：<span class="txt">{{item.email}}</span></div>
            </div>
            <div class="block">
              <div class="name">手机号：<span class="txt">{{item.telephone}}</span></div>
              <div class="birthday">身份证：<span class="txt">{{item.idCard}}</span></div>
              <div class="email">考试考点：<span class="txt">{{item.addressName}}</span></div>
            </div>
          </div>
          <div class="status-box" v-if="item.status==1">
            {{item.examTime | stringData}}
            <div class="down" @click="downLoad(item.memberExamId)">下载电子准考证</div>
          </div>
          <div class="status-box" v-else-if="item.status==2">
            预约已取消
          </div>
          <div class="status-box status0" v-else>
            考生信息确认中
          </div>
        </div>
      </div>
    </div>
    <el-pagination 
      background 
      :current-page="page" 
      @current-change="getMyExamList"
      v-if="memberExamList.length"
      layout="prev, pager, next" 
      :page-count="totalPage"/>
    <el-empty description="暂无数据" v-if="!memberExamList.length"></el-empty>
    <div ref="credentials" class="canvas-content">
      <div class="wrap">
        <div class="card-title">IP规划师职业等级考试</div>
        <div class="card-name">准考证</div>
        <div class="info">
          <img class="head-img" :src="examTicketInfo.photo" alt="">
          <div class="box">
            <div class="row">
              <div class="name">姓名：{{examTicketInfo.name}}</div>
              <div class="gender">姓别：{{examTicketInfo.sex}}</div>
            </div>
            <div class="row">准考证号：{{examTicketInfo.examNumber}}</div>
            <div class="row" style="margin-bottom:0">身份证号： <span style="font-size:46px">{{examTicketInfo.idCard}}</span> </div>
          </div>
        </div>
        <div class="row">考试科目：{{examTicketInfo.courseName}}</div>
        <div class="row">考试地点：{{examTicketInfo.addressName}} </div>
        <div class="row">考试时间：{{examTicketInfo.examTime}}</div>
        <div class="row">考场：{{examTicketInfo.examRoom}}</div>
        <div class="row" style="margin-bottom:0">座位：{{examTicketInfo.seatNumber}}</div>
        <img src="@/assets/images/seal.png" class="seal-img" alt="">
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import html2canvas from "html2canvas";
export default {
	filters: {
		stringData: function (date) {
			return moment(date*1000).format("YYYY-MM-DD HH:mm");
		},
	},
  data() {
    return {
      page:1,
      totalPage:0,
      examTicketInfo:{},
      memberExamList:[],
    };
  },
  created(){
    this.getMyExamList(this.page);
  },
  components: {},
  methods:{
    getMyExamList(page){
      // console.log(page)
      this.$http
      .get('/api/web/exam/getMyExamList?page'+page)
      .then(res=>{
        this.memberExamList=res.data.memberExamList;
        this.totalPage=res.data.totalPage;
      })
    },
    getExamTicketInfo(id){
      this.$http
      .get('/api/web/exam/getExamTicketInfo?memberExamId='+id)
      .then(res=>{
        this.examTicketInfo=res.data.examTicketInfo;
        this.downLoad()
      })
    },
    downLoad(id){
      this.$http
      .get('/api/web/exam/getExamTicketInfo?memberExamId='+id)
      .then(res=>{
        this.examTicketInfo=res.data.examTicketInfo;
        this.$nextTick(() => {
          setTimeout(() => {
            html2canvas(this.$refs.credentials, {
              backgroundColor: "transparent",
              allowTaint: true,
              useCORS: true,
            }).then((canvas) => {
              this.imgUrl = canvas.toDataURL();
              this.saveImgToDown()
            });
          }, 500);
        })
      })
    },
    saveImgToDown() {
      const link = document.createElement("a");
      link.href = this.imgUrl;
      link.setAttribute("download", `${this.examTicketInfo.name}-准考证.png`);
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
    },
  }
};
</script>

<style lang='less' scoped>
.my-exam-container{
  margin-top: 0px;
  padding-bottom: 50px;
  background: #fff;
  position: relative;
  min-height: 570px;
  .list{
    padding:30px 40px;
    .item{
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;
      border: 1px solid #EAEAEA;
      color: #333;
      font-size: 14px;
      &:last-child{
        margin-bottom: 0;
      }
      .txt{
        color: #666;
      }
      .item-title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        height: 50px;
        background: #F5F5F5;
        font-size: 16px;
        color: #333;
        letter-spacing: 1px;
        font-weight: 700;
        .txt{
          color: #333;
        }
      }
      .item-content{
        display: flex;
        justify-content: flex-start;
        .content{
          padding: 20px;
          display: flex;
          flex: 1;
        }
        .img-box{
          width: 154px;
          height: 90px;
          background: #F5F5F5;
          border-radius: 4px;
          .head-img{
            width: 68px;
            height: 88px;
          }
        }
        
        .block{
          flex: 0 0 calc(50% - 150px);
          display: flex;
          flex-direction: column;
          margin-left: 40px;
          text-align: left;
          justify-content: space-between;
          // width: 200px;
        }
      }
      .status-box{
        width: 200px;
        border-left: 2px solid #f5f5f5;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 700;
        color: #D71A18;
        &.status0{
          color: #ddd;
        }
        .down{
          cursor: pointer;
          margin-top: 30px;
        }
      }
    }
  }
  .canvas-content{
    position: absolute;
    top: 0;
    left: -99999px;
    background: #fff;
    width: 1240px;
    height: 1700px;
    padding: 50px;
    .wrap{
      width: 1140px;
      height: 1600px;
      padding: 55px 20px 52px 63px;
      border: 1px solid #000;
    }
    .seal-img{
      position: absolute;
      right: 126px;
      bottom: 125px;
      width: 363px;
      height: 363px;
    }
    .row{
      display: flex;
      font-size: 45px;
      margin-bottom: 70px;
      line-height: 1;
    }
    .card-title{
      font-size: 60px;
    }
    .card-name{
      margin: 56px 0 85px;
      font-size: 90px;
      font-weight: 700;
      letter-spacing: 20px;
    }
    .info{
      display: flex;
      width: 100%;
      margin-bottom: 88px;
      .box{
        display: flex;
        flex-direction: column;
        margin-left: 45px;
        padding-top: 40px;
      }
      .row{
        margin-bottom: 88px;
      }
      .head-img{
        width: 320px;
        height: 420px;
      }
      .name{
        margin-right: 80px;
      }
    }
  }
}
</style>
